import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';
import { type ContractLimitFront, ORGANIZATION_FORM_ID } from '../../constants';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import { useErrorTranslations, useHoldingTranslations } from 'customHooks/translations';
import { OrganizationFormValues } from 'types/entities/holdings';
import Select from 'components/ui/formComponents2/select/Select';
import useCountries from 'components/measure/vehicles/hooks/useCountries';
import { getSectorOptions } from 'utils/sector';
import { getLanguage } from 'utils/lang';
import { useFormWithHelpers } from 'lib/ReactHookForm/useFormWithHelpers';
import { GrayBox } from 'components/ui/GrayBox';
import Tooltip from 'components/ui/tooltip/Tooltip';
import Button from 'components/ui/button/Button';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';

type Props = {
  zodSchema: Parameters<typeof zodResolver>[0];
  onSubmit: SubmitHandler<OrganizationFormValues>;
  onInvalid?: SubmitErrorHandler<OrganizationFormValues>;
  defaultValues: OrganizationFormValues;
  contractLimits?: ContractLimitFront;
};

export const OrganizationForm = ({
  onSubmit,
  onInvalid,
  zodSchema,
  defaultValues,
  contractLimits
}: Props) => {
  const { t, i18n } = useHoldingTranslations();

  const { t: tError } = useErrorTranslations();

  const { handleSubmit, register, registerNumber, onSelectionChange, getSelectValue, formState } =
    useFormWithHelpers({
      resolver: zodResolver(zodSchema),
      defaultValues
    });

  const countries = useCountries();

  const sectors = getSectorOptions(getLanguage(i18n));

  if (!contractLimits) {
    return null;
  }

  const { employees, facilities, vehicles } = contractLimits;

  return (
    <form
      id={ORGANIZATION_FORM_ID}
      onSubmit={handleSubmit(onSubmit, onInvalid)}
      className='grid grid-cols-2 gap-y-8 gap-x-2'>
      <InputWrapper
        iconV2='cards'
        label={t('organizationForm.nameLabel')}
        error={adaptHookFormError(formState.errors.name, tError)}>
        <InputText {...register('name')} placeholder={t('organizationForm.namePlaceholder')} />
      </InputWrapper>
      <InputWrapper
        iconV2='earth'
        label={t('organizationForm.countryLabel')}
        error={adaptHookFormError(formState.errors.country, tError)}>
        <Select
          value={getSelectValue('country', countries)}
          onChange={onSelectionChange('country')}
          options={countries}
        />
      </InputWrapper>
      <InputWrapper
        iconV2='vat'
        label={t('organizationForm.cifLabel')}
        error={adaptHookFormError(formState.errors.cif, tError)}>
        <InputText {...register('cif')} placeholder={t('organizationForm.cifPlaceholder')} />
      </InputWrapper>
      <InputWrapper
        iconV2='sector'
        label={t('organizationForm.sectorLabel')}
        error={adaptHookFormError(formState.errors.sector, tError)}>
        <Select
          value={getSelectValue('sector', sectors)}
          onChange={onSelectionChange('sector')}
          options={sectors}
        />
      </InputWrapper>
      <GrayBox.Padless className='p-4 col-span-2 flex flex-col gap-y-4'>
        <span className='font-12 weight-600 inter text-neutral-gray-cold-20 flex gap-x-2 items-center'>
          {t('organizationForm.limitsTitle')}
          <Tooltip.Uncontrolled
            text={
              <span className='respect-line-breaks'>{t('organizationForm.limitsTooltip')}</span>
            }
            position='right'
          />
        </span>
        <div className='grid grid-cols-3 gap-x-2'>
          <div className='flex-col gap-y-2'>
            <InputWrapper
              iconV2='facilities'
              label={t('organizationForm.facilitiesLabel')}
              error={adaptHookFormError(formState.errors.limit_facilities, tError)}>
              <InputNumber
                placeholder={t('organizationForm.facilitiesPlaceholder')}
                {...registerNumber('limit_facilities')}
              />
            </InputWrapper>
            <span className='font-12 weight-400 inter text-neutral-gray-cold-20'>
              {t('organizationForm.available', {
                available: facilities.totalAvailable,
                total: facilities.total
              })}
            </span>
          </div>
          <div className='flex-col gap-y-2'>
            <InputWrapper
              iconV2='car'
              label={t('organizationForm.vehiclesLabel')}
              error={adaptHookFormError(formState.errors.limit_vehicles, tError)}>
              <InputNumber
                placeholder={t('organizationForm.vehiclesPlaceholder')}
                {...registerNumber('limit_vehicles')}
              />
            </InputWrapper>

            <span className='font-12 weight-400 inter text-neutral-gray-cold-20'>
              {t('organizationForm.available', {
                available: vehicles.totalAvailable,
                total: vehicles.total
              })}
            </span>
          </div>
          <div className='flex-col gap-y-2'>
            <InputWrapper
              iconV2='user'
              label={t('organizationForm.employeesLabel')}
              error={adaptHookFormError(formState.errors.limit_employees, tError)}>
              <InputNumber
                placeholder={t('organizationForm.employeesPlaceholder')}
                {...registerNumber('limit_employees')}
              />
            </InputWrapper>
            <span className='font-12 weight-400 inter text-neutral-gray-cold-20'>
              {t('organizationForm.available', {
                available: employees.totalAvailable,
                total: employees.total
              })}
            </span>
          </div>
        </div>
      </GrayBox.Padless>
    </form>
  );
};

type SubmitProps = {
  loading?: boolean;
};

const SubmitBTN = ({ loading }: SubmitProps) => {
  const { t } = useHoldingTranslations();

  return (
    <Button
      lookAndFeel='primary'
      size='medium'
      loading={loading}
      type='submit'
      form={ORGANIZATION_FORM_ID}
      text={t('organizationForm.submit')}
    />
  );
};

OrganizationForm.SubmitBTN = SubmitBTN;
