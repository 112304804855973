import Modal from 'components/ui/modal/Modal';
import { useModalManager } from '../ModalManager';
import { OrganizationForm } from '../OrganizationForm';
import { generateOrganizationSchema } from '../../schemas/organizationSchema';
import { SubmitHandler } from 'react-hook-form';
import { HoldingNode, OrganizationFormValues } from 'types/entities/holdings';
import { adapterHoldingNodeToFormValues } from 'adapters/adaptHoldingBlocks';
import Button from 'components/ui/button/Button';
import { useHoldingTranslations } from 'customHooks/translations';
import { useCreateOrUpdateOrganization } from '../../hooks/useCreateOrUpdateOrganization';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useContractParent } from '../../hooks/useContractParent';
import { adapterCreateOrganization } from 'adapters/adaptOrganizations';
import { useNode } from 'lib/ReactFlow/useNode';
import { useContractLimits } from '../../hooks/useContractLimits';

type OrganizationModalProps = {
  node_id: string;
};

export const OrganizationModal: React.FC<OrganizationModalProps> = ({ node_id }) => {
  const { closeModal } = useModalManager();

  const { createOrUpdateOrganization, isMutating } = useCreateOrUpdateOrganization();

  const org = useSelectedOrganization();
  const { data: contractOrgId } = useContractParent(org?.id);

  const { data: contractLimits, isLoading } = useContractLimits({
    canvas_org_id: contractOrgId,
    organization_id: org?.id
  });

  const node = useNode<HoldingNode>(node_id);

  const { t } = useHoldingTranslations();

  const defaultValues = adapterHoldingNodeToFormValues(node);

  const onSubmit: SubmitHandler<OrganizationFormValues> = async (data) => {
    if (!contractOrgId || !node?.id || isMutating) {
      return;
    }

    await createOrUpdateOrganization(
      adapterCreateOrganization({
        form: data,
        contract_org_id: contractOrgId,
        organization_id: node.data.org_id,
        node_id: node?.id
      })
    );

    closeModal();
  };

  const onCancel = () => {
    if (isMutating) {
      return;
    }

    closeModal();
  };

  return (
    <Modal.WithPortal show onClose={closeModal}>
      <Modal.Header title={t('organizationModal.title')} />
      {!isLoading && (
        <>
          <Modal.Content>
            <OrganizationForm
              onSubmit={onSubmit}
              zodSchema={generateOrganizationSchema(defaultValues, contractLimits)}
              defaultValues={defaultValues}
              contractLimits={contractLimits}
            />
          </Modal.Content>
          <Modal.Buttons>
            <Button lookAndFeel='secondary' size='medium' onClick={onCancel} loading={isMutating}>
              {t('organizationModal.cancel')}
            </Button>
            <OrganizationForm.SubmitBTN loading={isMutating} />
          </Modal.Buttons>
        </>
      )}
    </Modal.WithPortal>
  );
};
