import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { deleteConnection } from 'services/api/holdings';
import { useContractParent } from './useContractParent';
import type {
  GetHoldingBlocksQueryKey,
  GetHoldingConnectionsQueryKey,
  HoldingConnectionBack,
  OrganizationBlockBack
} from 'types/entities/holdings';

export const useDeleteConnection = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();
  const { data: contractOrgId } = useContractParent(org?.id);

  const { mutate } = useMutation({
    mutationFn: deleteConnection,
    onMutate: ({ source_id, target_id }) => {
      const connections_snapshot = queryClient.getQueryData<
        HoldingConnectionBack[],
        GetHoldingConnectionsQueryKey
      >(['holding-connections', contractOrgId, org?.id]);

      const blocks_snapshot = queryClient.getQueryData<
        OrganizationBlockBack[],
        GetHoldingBlocksQueryKey
      >(['holding-blocks', contractOrgId, 'connections', org?.id]);

      queryClient.setQueryData<HoldingConnectionBack[], GetHoldingConnectionsQueryKey>(
        ['holding-connections', contractOrgId, org?.id],
        (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return oldData.filter((connection) => {
            return connection.source_id !== source_id || connection.target_id !== target_id;
          });
        }
      );

      queryClient.setQueryData<OrganizationBlockBack[], GetHoldingBlocksQueryKey>(
        ['holding-blocks', contractOrgId, 'connections', org?.id],
        (oldData) => {
          return oldData?.map((block) => {
            if (block.node_id === source_id) {
              return {
                ...block,
                outputs: block.outputs?.filter((output) => output !== target_id) ?? []
              };
            }

            if (block.node_id === target_id) {
              return {
                ...block,
                inputs: block.inputs?.filter((input) => input !== source_id) ?? []
              };
            }

            return block;
          });
        }
      );

      return { connections_snapshot, blocks_snapshot };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(
        ['holding-connections', contractOrgId, org?.id],
        context?.connections_snapshot
      );

      queryClient.setQueryData(
        ['holding-blocks', contractOrgId, 'connections', org?.id],
        context?.blocks_snapshot
      );
    }
  });

  return { deleteConnection: mutate };
};
