import { useInternalNode } from '@xyflow/react';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import type { HoldingNode } from 'types/entities/holdings';
import { useCreateOrganizationBlock } from '../../hooks/useCreateOrganizationBlock';
import { adapterCreateOrganizationBlock } from 'adapters/adaptHoldingBlocks';

type Props = {
  node_id: string;
};

export const CreateNodeBTN: React.FC<Props> = ({ node_id }) => {
  const nodeInfo = useInternalNode<HoldingNode>(node_id);
  const { createOrganizationBlock, isPending } = useCreateOrganizationBlock();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!nodeInfo || isPending) {
      return;
    }

    createOrganizationBlock(
      adapterCreateOrganizationBlock({
        source_id: node_id,
        source_x: nodeInfo?.position.x,
        source_y: nodeInfo?.position.y
      })
    );
  };

  return (
    <Button
      lookAndFeel='secondary'
      style={{
        position: 'absolute',
        right: '50%',
        width: '2rem',
        height: '2rem',
        bottom: '-2.5rem'
      }}
      loading={isPending}
      onClick={onClick}
      iconNode={!isPending && <Icon icon='add' color='gray-dark' size='small' />}
      size='small'
    />
  );
};
