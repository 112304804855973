import { CustomNode } from 'components/ui/canvas';
import { HoldingIcon } from '../HoldingIcon';
import { IconName } from 'types/utils/iconName';
import Icon from 'components/ui/icon/Icon';
import React, { forwardRef } from 'react';
import { BlueTag } from 'components/ui/blueTag/BlueTag';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { useHoldingTranslations } from 'customHooks/translations';
import { isKey } from 'utils/isKey';
import type { HoldingNodeData } from 'types/entities/holdings';

type InfoProps = {
  icon: IconName;
  children: React.ReactNode;
  disabled?: boolean;
};

type InfoTooltipProps<
  DataType extends { limits: Record<string, number> },
  _Category extends keyof DataType & string
> = {
  data: DataType;
  category: _Category;
  children: React.ReactNode;
};

const InfoTooltip = <DataType extends HoldingNodeData, _Category extends keyof DataType & string>({
  data,
  category,
  children
}: InfoTooltipProps<DataType, _Category>) => {
  const { t } = useHoldingTranslations();

  return (
    <TooltipWrapper.Uncontrolled
      shouldAppear={Boolean(data.org_id)}
      position='right'
      text={
        <span className='respect-line-breaks'>
          {t(`infoTooltip.${category}`, {
            count: data[category] as number,
            limit: isKey(category, data.limits) && data.limits[category] ? data.limits[category] : 0
          })}
        </span>
      }
      asChild>
      {children}
    </TooltipWrapper.Uncontrolled>
  );
};

const Info = forwardRef<HTMLSpanElement, InfoProps>(
  ({ children, icon, disabled, ...rest }, ref) => {
    if (disabled) {
      return (
        <BlueTag disabled ref={ref} {...rest}>
          <div className='flex gap-x-1 items-center'>
            <Icon icon={icon} color='disabled' />
            {children}
          </div>
        </BlueTag>
      );
    }

    return (
      <BlueTag ref={ref} {...rest}>
        <div className='flex gap-x-1 items-center'>
          <Icon icon={icon} color='blocked' />
          {children}
        </div>
      </BlueTag>
    );
  }
);

Info.displayName = 'Info';

const Content: React.FC<React.ComponentProps<typeof CustomNode.Content>> = ({ children }) => {
  return <div className='flex gap-x-5'>{children}</div>;
};

export const HoldingCustomNode = {
  Layout: CustomNode.Layout,
  Content,
  Icon: HoldingIcon,
  Info: Object.assign(Info, { Tooltip: InfoTooltip }),
  Tag: CustomNode.Tag
};
