import type {
  OrganizationFormValues,
  PayloadCreateOrUpdateOrganization
} from 'types/entities/holdings';

type AdapterCreateOrganizationParams = {
  form: OrganizationFormValues;
  organization_id: string;
  contract_org_id: string;
  node_id: string;
};

export const adapterCreateOrganization = ({
  form,
  organization_id,
  contract_org_id,
  node_id
}: AdapterCreateOrganizationParams): PayloadCreateOrUpdateOrganization => {
  return {
    canvas_organization_id: contract_org_id,
    organization_id: organization_id || null,
    node_id: node_id,
    company_name: form.name,
    cif: form.cif,
    country: form.country,
    sector: form.sector,
    limit_facilities: parseInt(form.limit_facilities),
    limit_vehicles: parseInt(form.limit_vehicles),
    limit_employees: parseInt(form.limit_employees)
  };
};
