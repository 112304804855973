import { useQuery } from '@tanstack/react-query';
import { getContractParent } from 'services/api/holdings';

export const useContractParent = (organizationId?: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['contract-parent', organizationId],
    queryFn: getContractParent,
    enabled: !!organizationId
  });

  return { data, isLoading };
};
