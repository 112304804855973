import { BackgroundVariant, type ReactFlowProps } from '@xyflow/react';
import { CompanyNode } from './components/CustomNodes';
import { DefaultEdge, ShareEdge } from './components/CustomEdges';

export const DEFAULT_Y_GAP = 240;
export const DEFAULT_X_GAP = 340;

export const DIAGRAM_CONFIG = {
  NODE_ORIGIN: [0.5, 0.5] as ReactFlowProps['nodeOrigin'],
  MIN_ZOOM: 0.0001 as ReactFlowProps['minZoom'],
  PRO_OPTIONS: {
    hideAttribution: true
  } as ReactFlowProps['proOptions'],
  NODE_DRAG_THRESHOLD: 3 as ReactFlowProps['nodeDragThreshold'],
  BG: {
    VARIANT: BackgroundVariant.Lines,
    GAP: 48
  },
  SNAP_GRID: [DEFAULT_X_GAP, DEFAULT_Y_GAP] as ReactFlowProps['snapGrid']
} as const;

export const NODE_TYPES = {
  COMPANY: 'company'
} as const;

export const NODE_COMPONENTS = {
  [NODE_TYPES.COMPANY]: CompanyNode
} as const;

export const EDGE_TYPES = {
  DEFAULT: 'default',
  SHARE: 'share'
} as const;

export const EDGE_COMPONENTS = {
  [EDGE_TYPES.DEFAULT]: DefaultEdge,
  [EDGE_TYPES.SHARE]: ShareEdge
} as const;

export const DEFAULT_X = 0;
export const DEFAULT_Y = 0;
export const ORGANIZATION_FORM_ID = 'organization-form';
export const SHARE_FORM_ID = 'share-form';

export const LIMIT_TYPES = {
  FACILITIES: 'facilities',
  VEHICLES: 'vehicles',
  EMPLOYEES: 'employees'
} as const;

export type ContractLimitTypeFront = (typeof LIMIT_TYPES)[keyof typeof LIMIT_TYPES];

export type ContractLimitFront = Record<
  ContractLimitTypeFront,
  { totalUsed: number; totalAvailable: number; total: number }
>;
