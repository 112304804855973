export const useTransportTOCOptions = () => {
  return {
    options: [
      {
        id: 'ca7b3d2c-b76d-4bbc-aeec-8f1efbc4f096',
        name: 'Coche'
      }
    ] as SelectOptionFormat<string>[]
  };
};
