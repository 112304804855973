import { forwardRef } from 'react';

type LayoutProps = {
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  disableHover?: boolean;
  className?: string;
};

const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ children, disabled, disableHover, className, selected }, ref) => {
    const classes = ['flex flex-col gap-y-4 p-4 border-box rounded-8 w-full'];

    if (selected) {
      classes.push('shadow-50-purple');
    }

    if (!selected) {
      classes.push('shadow-10');
    }

    if (disabled) {
      classes.push('bg-neutral-gray-warm-80');
    }

    if (!disabled) {
      classes.push('card-bg-color');
    }

    if (!disabled && !disableHover && !selected) {
      classes.push('hover:shadow-50');
    }

    if (className) {
      classes.push(className);
    }

    return (
      <div className={classes.join(' ')} ref={ref}>
        {children}
      </div>
    );
  }
);

Layout.displayName = 'CustomNodeLayout';

type ContentProps = {
  children: React.ReactNode;
};

const Content: React.FC<ContentProps> = ({ children }) => {
  return <div className='flex items-center gap-x-4'>{children}</div>;
};

type TagProps = {
  children: React.ReactNode;
  disabled?: boolean;
};

const Tag: React.FC<TagProps> = ({ children, disabled }) => {
  if (disabled) {
    return (
      <p className='flex items-center gap-x-1 m-0 text-tag font-body-b2-r text-gray-2 p-1 rounded-4 w-fit-content bg-gray-light-2'>
        {children}
      </p>
    );
  }

  return (
    <p className='flex items-center gap-x-1 m-0 text-tag font-body-b2-r tag-bg-text-color p-1 rounded-4 w-fit-content'>
      {children}
    </p>
  );
};

export const CustomNode = {
  Layout,
  Content,
  Tag
};
