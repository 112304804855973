import CompaniesGroupFunds from './groupFunds/companiesGroupFunds/CompaniesGroupFunds';
import CompaniesHolding from './holding/companiesHolding/CompaniesHolding';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import CompaniesWrapper from './common/companiesWrapper/CompaniesWrapper';
import { useSearchParams } from 'react-router-dom';
import ParentCompanies from './parentCompanies/ParentCompanies';
import { HoldingDiagram } from './Diagram/HoldingDiagram';
import { DEFAULT_STEP, STEP_IDS } from './constants';

const Companies = () => {
  const foundOrganization = useSelectedOrganization();
  const [searchParams] = useSearchParams({ type: DEFAULT_STEP });

  if (foundOrganization?.is_group_fund) {
    return (
      <CompaniesWrapper>
        <CompaniesGroupFunds />
      </CompaniesWrapper>
    );
  }

  if (searchParams.get('type') === STEP_IDS.DIAGRAM) {
    return (
      <section className='w-full h-full flex flex-col gap-y-4'>
        <CompaniesWrapper.Base>
          <HoldingDiagram />
        </CompaniesWrapper.Base>
      </section>
    );
  }

  return (
    <CompaniesWrapper>
      {searchParams.get('type') === STEP_IDS.PARENTS && <ParentCompanies />}
      {searchParams.get('type') === STEP_IDS.CHILDS && <CompaniesHolding />}
    </CompaniesWrapper>
  );
};

export default Companies;
