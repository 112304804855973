import Button from 'components/ui/button/Button';
import { HoldingCustomNode } from './HoldingCustomNode';
import Icon from 'components/ui/icon/Icon';
import { HoldingNodeData } from 'types/entities/holdings';
import { Handle, Position, useConnection } from '@xyflow/react';
import { useHover } from 'customHooks/useHover';
import {
  getOrgLabel,
  getSourceHandleStyle,
  getTargetHandleStyle,
  getVariant,
  isValidOrgNode
} from './utils';
import { useHoldingTranslations } from 'customHooks/translations';
import { CreateNodeBTN } from '../CreateNodeBTN';
import { SwitchOrgBTN } from '../SwitchOrgBTN';
import { useContractParent } from '../../hooks/useContractParent';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { getIsInOrg } from '../../utils';
import { MODAL_NAMES, useModalManager } from '../ModalManager';

type Props = {
  id: string;
  data: HoldingNodeData;
  selected?: boolean;
  positionAbsoluteY: number;
};

export const CompanyNode: React.FC<Props> = ({ id, data, selected, positionAbsoluteY }) => {
  const { inProgress } = useConnection();
  const { handleMouseEnter, handleMouseLeave, isHovered, nodeRef, isActivated } = useHover();
  const { openModal } = useModalManager();
  const { t } = useHoldingTranslations();

  const org = useSelectedOrganization();
  const { data: contractOrg } = useContractParent(org?.id);

  const isValidOrg = isValidOrgNode(data);

  const variant = getVariant(isValidOrg);

  const isInContractOrg = getIsInOrg(contractOrg, org);

  if (!org || !contractOrg) {
    return null;
  }

  const onClick = () => {
    openModal(MODAL_NAMES.ORGANIZATION, id);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={nodeRef}
      className='relative w-80'>
      {positionAbsoluteY > 0 && (
        <Handle
          type='target'
          position={Position.Top}
          isConnectableEnd={isInContractOrg}
          isConnectableStart={false}
          style={getTargetHandleStyle(inProgress)}
        />
      )}
      <HoldingCustomNode.Layout selected={selected || (inProgress && isHovered)}>
        <div className='flex justify-between gap-x-5'>
          <div className='flex flex-col gap-y-5'>
            <div className='flex gap-x-4'>
              <HoldingCustomNode.Icon icon='cards' disabled={!isValidOrg} />
              <div className='flex flex-col gap-y-1'>
                <span
                  className='weight-400 font-12 inter text-neutral-gray-warm-20 max-w-25ch ellipsis'
                  title={getOrgLabel(t, data.cif)}>
                  {getOrgLabel(t, data.cif)}
                </span>
                <span
                  className='weight-700 font-14 inter text-neutral-gray-cold-20 max-w-20ch ellipsis'
                  title={data.name}>
                  {data.name || '-'}
                </span>
              </div>
            </div>
            <div className='flex gap-x-2 items-center'>
              {isInContractOrg && (
                <Button
                  lookAndFeel={variant.lookAndFeel}
                  text={t(variant.i18nKey)}
                  onClick={onClick}
                  style={{ width: 'fit-content' }}
                  iconNode={<Icon icon={variant.icon.name} color={variant.icon.color} />}
                  size='small'
                />
              )}
              <SwitchOrgBTN org_id={data.org_id} />
            </div>
          </div>
          <div className='flex flex-col gap-y-2 items-end'>
            <HoldingCustomNode.Info.Tooltip data={data} category='facilities'>
              <HoldingCustomNode.Info icon='facilities' disabled={!isValidOrg}>
                {isValidOrg ? data.facilities : '-'}
              </HoldingCustomNode.Info>
            </HoldingCustomNode.Info.Tooltip>
            <HoldingCustomNode.Info.Tooltip data={data} category='vehicles'>
              <HoldingCustomNode.Info icon='car' disabled={!isValidOrg}>
                {isValidOrg ? data.vehicles : '-'}
              </HoldingCustomNode.Info>
            </HoldingCustomNode.Info.Tooltip>
            <HoldingCustomNode.Info.Tooltip data={data} category='employees'>
              <HoldingCustomNode.Info icon='user' disabled={!isValidOrg}>
                {isValidOrg ? data.employees : '-'}
              </HoldingCustomNode.Info>
            </HoldingCustomNode.Info.Tooltip>
          </div>
        </div>
      </HoldingCustomNode.Layout>
      <Handle
        type='source'
        position={Position.Bottom}
        className='custom-source-handle'
        style={getSourceHandleStyle(isActivated)}
        isConnectable={isInContractOrg}
      />
      {isActivated && isInContractOrg && <CreateNodeBTN node_id={data.id} />}
    </div>
  );
};
